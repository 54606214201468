export const loginInitialValues = {
  email: "",
  password: "",
  rememberMe: false,
};

export const addProductInitialValues = {
  productName: "",
  // productPrice: "",
  productKey: "",
  brand: "",
  tax: "",
  category: "",
  deleteCategory: "",
  deleteSubCategory: "",
  subCategory: "",
  specification: "",
  warehouseId: "",
  warehouse: "",
  description: "",
  // pieces: "",
  category: "",
  deletedSpecificatinIds: "",
  deletedVariantIds: "",
  // minQuantity: "",
  // maxQuantity: "",
  variants: "",
  brand: "",
  sku: "",
};

export const addCategoryInitialValues = {
  categoryName: "",
};
export const addFeatureInitialValues = {
  name: "",
};
export const addSubCategoryInitialValues = {
  subCategoryName: "",
  categoryId: "",
};
export const addDiscountInitialValues = {
  title: "",
  value: "",
};
export const filterValueInitialValues = {
  categoryName: "",
  status: "",
};

export const addCountryInitialValues = {
  countryName: "",
};

export const filterValueCountryInitialValues = {
  countryName: "",
  status: "",
};

export const addStateInitialValues = {
  stateName: "",
  countryId: "",
};

export const filterValueStateInitialValues = {
  stateName: "",
  countryId: "",
  status: "",
};

export const addCityInitialValues = {
  cityName: "",
  stateId: "",
  countryId: "",
};

export const filterValueCityInitialValues = {
  cityName: "",
  status: "",
  stateId: "",
  countryId: "",
  state: "",
  country: "",
};

export const filterValueSubCategoryInitialValues = {
  categoryId: "",
  subCategoryName: "",
  status: "",
};

export const changePasswordInitialValues = {
  newPassword: "",
  confirmpass: "",
  currentPassword: "",
};

export const filterValueProductInitialValues = {
  productName: "",
  categoryId: "",
  subCategory: "",
  status: "",
  formPrice: "",
  toPrice: "",
  formPriceSelect: "",
  toPriceSelect: "",
};

export const addAdminInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
};

export const filterAdminInitialValues = {
  status: "",
  role: "",
  email: "",
};

export const roleInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
};

export const filterRoleInitialValues = {
  status: "",
};

export const roleAddInitialValues = {
  roleName: "",
};

export const addCouponInitialValues = {
  couponCode: "",
  title: "",
  description: "",
  discountType: "",
  couponType: "",
  users: "",
  discountAmount: "",
  startDate: "",
  endDate: "",
  minAmount: "",
  maxAmount: "",
  maxUseCouponLimit: "",
  perUserUsage: "",
  countCheck: "",
};

export const filterCouponInitialValues = {
  status: "",
  startDate: "",
  endDate: "",
};

export const filterUserInitialValues = {
  status: "",
};

export const filterCompanyInitialValues = {
  status: "",

};

export const filterPurchaseOrderInitialValues = {
  status: "",
  orderStatus: "",
  paymentStatus: "",
};

export const assignTransporterInitialValues = {
  transportId: "",
  orderId: "",
};

export const paymentPurchaseOrderInitialValues = {
  paymentType: "",
  paymentDate: "",
  transactionId: "",
  chequeNumber: "",
  bankName: "",
  accountNumber: "",
  paymentAmount: "",
};

export const addCompanyInitialValues = {
  companyName: "",
  email: "",
  phoneNumber: "",
  vendorName: "",
  gstNumber: "",
  panNumber: "",
  addressLine1: "",
  addressLine2: "",
  state: "",
  stateId: "",
  city: "",
  cityId: "",
  zipCode: "",
  TAN: "",
  CIN: "",
};

export const editUserInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  addressLine1: "",
  addressLine2: "",
  state: "",
  stateId: "",
  city: "",
  cityId: "",
  zipCode: "",
  shippingAddressLine1: "",
  shippingAddressLine2: "",
  shippingState: "",
  shippingStateId: "",
  shippingCity: "",
  shippingCityId: "",
  shippingZipCode: "",
};

export const addWarehouseInitialValues = {
  warehouseName: "",
  email: "",
  phoneNumber: "",
  ownerName: "",
  companyName: "",
  gstNumber: "",
  panNumber: "",
  addressLine1: "",
  addressLine2: "",
  state: "",
  stateId: "",
  city: "",
  cityId: "",
  zipCode: "",
  warehouseKey: "",
};

export const userAddressInitialValues = {
  addressLine1: "",
  addressLine2: "",
  landmark: "",
  state: "",
  city: "",
  zipCode: "",
};

export const purchaseOrderInitialValues = {
  vendorId: "",
  vendor: "",
  orderNumber: "",
  purchaseDate: "",
  venderIncNo: "",
  tax: "",
  taxValue: "",
  subTotal: "",
  discountAmount: "",
  adjustment: "",
  payableAmount: "",
  adjustmentType: "1",
  payment: true,
  paymentType: "",
  paymentDate: "",
  transactionId: "",
  chequeNumber: "",
  bankName: "",
  accountNumber: "",
  paymentAmount: "",
};

export const profileInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  profileImage: "",
};

export const filterAppointmentInitialValues = {
  status: "",
  date: "",
  time: "",
  type: "",
};

export const filterContactInitialValues = {
  status: "",

};

export const filterTicketInitialValues = {
  status: "",
  
};
export const statusTicketInitialValues = {
  status: "",
  note: "",
  
};
export const statusAppointmentInitialValues = {
  note: "",
  message: "",
};

export const filterCartInitialValues = {
  status: "",
};

export const addCartInitialValues = {
  userId: "",
  userStaeName: "",
  adminStateName: "",
  cgst: "",
  sgst: "",
  igst: "",
  cgstp: "",
  sgstp: "",
  igstp: "",
  taxValue: "",
  subTotal: "",
  discountAmount: "",
  adjustment: "",
  payableAmount: "",
  adjustmentType: "1",
};

export const filterTaxInitialValues = {
  status: "",
};

export const addTaxInitialValues = {
  name: "",
  label: "",
  description: "",
  taxValue: "",
  taxType: "",
};

export const slotInitialValues = {
  startTime: "",
  endTime: "",
  duration: "",
  startTime1: "",
  endTime1: "",
  duration1: "",
};
export const addUomInitialValues = {
  name: "",
}

export const addOfferInitialValues = {
  offerName: "",
  offerType: "", 
  buyX: "",
  getY: "",
  discountPercent: "",
  discountAmount: "",
  applicableProducts: "", 
  minimumCartValue: "",
  startDate: "", 
  endDate: "",   
};
